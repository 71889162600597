<template>
    <div class="js-entry o-entry c-menu-entry" role="navigation">
            <ul class="c-menu-entry" v-bind:class="{ type_image: entryData.Json.Type==='image' }">
                <div v-if="entryData.Json.Type==='image'" >
                    <div v-for="item in this.entryData.MenuItems" :key="item.id" class="c-menu-entry__item">
                        <xp-bg-img v-if="item.Image" :tag="'div'" class="c-menu-entry__item-imagewrapper"
                            :src="$xp.content.getImageUrl(item.Image, preloadImageWidth)"
                            :placeholderSrc="item.Image.Thumb"
                        />
                        <div v-else class="c-menu-entry__item-imagewrapper"/>
                        <router-link class="c-menu-entry__item-title" tag="div" :to="'/page/' + item.ID" >
                            {{ item.Title }}
                            <div v-if="item.SubTitle && item.SubTitle.length" class="c-menu-entry__item-subtitle">{{ item.SubTitle }}</div>
                        </router-link>
                    </div>
                </div>
                <div v-else>
                    <router-link tag="li" :to="'/page/' + item.ID" class="c-menu-entry__item" v-for="item in this.entryData.MenuItems" :key="item.id">
                        {{ item.Title }}
                    </router-link>
                </div>
            </ul>
        </div>
    <!-- </div> -->
</template>

<script>
import { entryMixin } from '@xpedeo/core'
import XpBgImg from '@xpedeo/core/dist/components/XpImg'
// import XpBgImg from '../XpBgImg'

export default {
  mixins: [entryMixin],
  components: { XpBgImg },
  computed: {
    preloadImageWidth () {
      return Math.min(...this.$xp.settings.mergedSettings.content.imageResolutions)
    }
  }
}
</script>
